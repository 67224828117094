import { DropZone, type Config } from '@measured/puck';
import { Typography, Image, Row, Col, Checkbox } from 'antd';
import { TwitterPicker } from 'react-color';

const { Title, Paragraph, Text, Link } = Typography;

const TextStyles = {
  textAlign: {
    label: 'Text Align',
    type: 'select',
    options: [
      {
        label: 'Izquierda',
        value: 'left',
      },
      {
        label: 'Centro',
        value: 'center',
      },
      {
        label: 'Derecha',
        value: 'right',
      },
    ],
  },
  color: {
    label: 'Color',
    type: 'custom',
    render: ({ onChange, value }: any) => {
      return (
        <div className="color-picker-tw">
          <h5>Color: </h5>
          <TwitterPicker
            triangle="hide"
            color={value}
            onChange={(color) => onChange(color.hex)}
          />
        </div>
      );
    },
  },
  fontSize: {
    label: 'Font Size',
    type: 'number',
  },
  fontWeight: {
    label: 'Font Weight',
    type: 'select',
    options: [
      {
        label: 'Normal',
        value: 'normal',
      },
      {
        label: 'Bold',
        value: 'bold',
      },
    ],
  },
  fontStyle: {
    label: 'Font Style',
    type: 'select',
    options: [
      {
        label: 'Normal',
        value: 'normal',
      },
      {
        label: 'Italic',
        value: 'italic',
      },
    ],
  },
};
const ContainerStyles = {
  width: {
    label: 'Ancho',
    type: 'number',
  },
  height: {
    label: 'Altura',
    type: 'number',
  },
  textAlign: {
    label: 'Alineado de contenido',
    type: 'select',
    options: [
      {
        label: 'Izquierda',
        value: 'left',
      },
      {
        label: 'Centro',
        value: 'center',
      },
      {
        label: 'Derecha',
        value: 'right',
      },
    ],
  },
  margin: {
    label: 'Margen',
    type: 'text',
  },
  padding: {
    label: 'Relleno',
    type: 'text',
  },
};
type Components = {
  Text: { text: string; styles: Record<string, string | number> };
  Title: { text: string; styles: Record<string, string | number> };
  Paragraph: { text: string; styles: Record<string, string | number> };
  Link: { text: string; url: string; styles: Record<string, string | number> };
  Image: {
    src: string;
    alt: string;
    width: number;
    preview: boolean;
    styles: Record<string, string | number>;
  };
  Html: { html: string; styles?: Record<string, string | number> };
  Row: {
    justify:
      | 'start'
      | 'center'
      | 'end'
      | 'space-around'
      | 'space-between'
      | 'space-evenly';
    align: 'top' | 'middle' | 'bottom' | 'stretch';
    columns: number;
    columnSpan: {
      span: number;
      md?: number;
      xs?: number;
      xl?: number;
      xxl?: number;
      styles?: Record<string, string | number>;
    }[];
    styles?: Record<string, string | number>;
  };
  Container: {
    styles: Record<string, string | number>;
  };
};
export const PuckConfig: Config<Components> = {
  root: {
    label: 'Página',
  },
  categories: {
    typography: {
      title: 'Tipografía',
      components: ['Title', 'Paragraph', 'Text', 'Link'],
    },
    code: {
      title: 'Código',
      components: ['Html'],
    },
    layout: {
      title: 'Diseño',
      components: ['Row', 'Container'],
    },
    visual: {
      title: 'Visual',
      components: ['Image'],
    },
  },
  components: {
    Text: {
      label: 'Texto',
      fields: {
        text: {
          label: 'Text',
          type: 'text',
        },
        styles: {
          label: 'Estilos',
          type: 'object',
          objectFields: TextStyles as any,
        },
      },
      defaultProps: {
        text: 'Text',
        styles: {
          textAlign: 'left',
          color: '#000000',
          fontSize: 16,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
      },
      render: ({ text, styles }) => {
        return <Text style={styles}>{text}</Text>;
      },
    },
    Title: {
      label: 'Título',
      fields: {
        text: {
          label: 'Text',
          type: 'text',
        },
        styles: {
          label: 'Estilos',
          type: 'object',
          objectFields: TextStyles as any,
        },
      },
      defaultProps: {
        text: 'Title',
        styles: {
          textAlign: 'left',
          color: '#000000',
          fontSize: 24,
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      },
      render: ({ text, styles }) => {
        return <Title style={styles}>{text}</Title>;
      },
    },
    Paragraph: {
      label: 'Párrafo',
      fields: {
        text: {
          label: 'Text',
          type: 'text',
        },
        styles: {
          label: 'Estilos',
          type: 'object',
          objectFields: TextStyles as any,
        },
      },
      defaultProps: {
        text: 'Paragraph',
        styles: {
          textAlign: 'left',
          color: '#000000',
          fontSize: 16,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
      },
      render: ({ text, styles }) => {
        return <Paragraph style={styles}>{text}</Paragraph>;
      },
    },
    Link: {
      label: 'Enlace',
      fields: {
        text: {
          label: 'Text',
          type: 'text',
        },
        url: {
          label: 'URL',
          type: 'text',
        },
        styles: {
          label: 'Estilos',
          type: 'object',
          objectFields: TextStyles as any,
        },
      },
      defaultProps: {
        text: 'Link',
        url: '',
        styles: {},
      },
      render: ({ text, url, styles }) => {
        return (
          <Link href={url} target="" style={styles}>
            {text}
          </Link>
        );
      },
    },
    Image: {
      label: 'Imagen',
      fields: {
        src: {
          label: 'Image URL',
          type: 'text',
        },
        alt: {
          label: 'Alt Text',
          type: 'text',
        },
        width: {
          label: 'Width',
          type: 'number',
        },
        preview: {
          label: 'Visualización',
          type: 'custom',
          render: ({ onChange, value, name }) => {
            return (
              <Checkbox
                name={name}
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              >
                <h4>Permitir vista previa</h4>
              </Checkbox>
            );
          },
        },
        styles: {
          label: 'Estilos',
          type: 'object',
          objectFields: ContainerStyles as any,
        },
      },
      defaultProps: {
        src: 'https://via.placeholder.com/200',
        alt: 'Placeholder',
        width: 200,
        styles: {},
        preview: false,
      },
      render: ({ src, alt, width, preview, styles }) => {
        return (
          <Image src={src} alt={alt} preview={preview} width={width} style={styles} />
        );
      },
    },
    Row: {
      label: 'Fila',
      fields: {
        columns: {
          label: 'Columnas',
          type: 'number',
        },
        columnSpan: {
          label: 'Column Span',
          type: 'array',
          arrayFields: {
            span: { type: 'number' },
            md: { type: 'number' },
            xs: { type: 'number' },
            xl: { type: 'number' },
            xxl: { type: 'number' },
            styles: { type: 'object', objectFields: ContainerStyles as any },
          },
        },
        justify: {
          label: 'Justificar',
          type: 'select',
          options: [
            {
              label: 'Comienzo',
              value: 'start',
            },
            {
              label: 'Centro',
              value: 'center',
            },
            {
              label: 'Fin',
              value: 'end',
            },
            {
              label: 'Espacio alrededor',
              value: 'space-around',
            },
            {
              label: 'Espacio entre',
              value: 'space-between',
            },
            {
              label: 'Espacio uniforme',
              value: 'space-evenly',
            },
          ],
        },
        align: {
          label: 'Alinear',
          type: 'select',
          options: [
            {
              label: 'Arriba',
              value: 'top',
            },
            {
              label: 'Centro',
              value: 'middle',
            },
            {
              label: 'Abajo',
              value: 'bottom',
            },
            {
              label: 'Estirar',
              value: 'stretch',
            },
          ],
        },
      },
      defaultProps: {
        columns: 2,
        columnSpan: [{ span: 12 }, { span: 12 }],
        justify: 'start',
        align: 'top',
      },
      render: ({ columns, columnSpan, justify, align, styles }) => {
        const cols = [];
        for (let i = 0; i < columns; i++) {
          cols.push(
            <Col key={i} span={columnSpan[i]?.span} style={columnSpan[i]?.styles}>
              <DropZone zone={`column-${i}`} key={i} />
            </Col>,
          );
        }
        return (
          <Row justify={justify} align={align} style={styles}>
            {cols}
          </Row>
        );
      },
    },
    Html: {
      label: 'Bloque HTML',
      fields: {
        html: {
          label: 'HTML',
          type: 'text',
        },
      },
      defaultProps: {
        html: '<p>HTML</p>',
      },
      render: ({ html }) => {
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
      },
    },
    Container: {
      label: 'Contenedor',
      fields: {
        styles: {
          label: 'Estilos',
          type: 'object',
          objectFields: {
            height: {
              label: 'Altura',
              type: 'number',
            },
            width: {
              label: 'Ancho',
              type: 'number',
            },
            textAlign: {
              label: 'Alineación de contenido',
              type: 'select',
              options: [
                {
                  label: 'Izquierda',
                  value: 'left',
                },
                {
                  label: 'Centro',
                  value: 'center',
                },
                {
                  label: 'Derecha',
                  value: 'right',
                },
              ],
            },
            backgroundColor: {
              label: 'Color de fondo',
              type: 'custom',
              render: ({ onChange, value }: any) => {
                return (
                  <div className="color-picker-tw">
                    <h5>Color: </h5>
                    <TwitterPicker
                      triangle="hide"
                      color={value}
                      onChange={(color) => onChange(color.hex)}
                    />
                  </div>
                );
              },
            },
            padding: {
              label: 'Relleno',
              type: 'number',
            },
            margin: {
              label: 'Margen',
              type: 'number',
            },
            borderRadius: {
              label: 'Radio de borde',
              type: 'number',
            },
          },
        },
      },
      defaultProps: {
        styles: {
          textAlign: 'left',
          backgroundColor: '#ffffff',
          padding: 0,
          margin: 0,
          borderRadius: 0,
        },
      },
      render: ({ styles }) => {
        return (
          <div style={styles}>
            <DropZone zone="container" />
          </div>
        );
      },
    },
  },
};
