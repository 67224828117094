import APIService from '@/services/API';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const TypeSelector: React.FC = ({ ...props }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { data: Types, isLoading } = useQuery(['types'], () =>
    APIService.getTypes({
      $limit: '100',
    }),
  );
  return (
    <Select
      style={{ width: 105 }}
      loading={isLoading}
      showSearch
      options={Types?.data?.map((row) => ({
        label: row.name[language as 'en' | 'es'],
        value: row._id,
      }))}
      {...props}
    />
  );
};

export default TypeSelector;
