import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const STATUS = ['draft', 'pending', 'publish', 'soft-delete'];
const StatusSelector: React.FC = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Select
      placeholder={t('general.status')}
      showSearch
      options={STATUS.map((row) => ({
        label: t(`status.${row}`),
        value: row,
      }))}
      {...props}
    />
  );
};

export default StatusSelector;
